import { compile } from "path-to-regexp";

import { ROUTE_PATHS } from "@/router/paths";

export function getUsersCreateUrl() {
  return ROUTE_PATHS.USERS_CREATE;
}

export function getUsersUpdateUrl(id: number): string {
  return compile(ROUTE_PATHS.USERS_EDIT)({ userId: String(id) });
}

export function getAddressesUpdateUrl(id: number): string {
  return compile(ROUTE_PATHS.ADDRESSES_EDIT)({ addressId: String(id) });
}

export function getUsersListUrl(): string {
  return ROUTE_PATHS.USERS_LIST;
}
