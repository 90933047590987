
import { computed, defineComponent, ref } from "vue";

import {
  FormField,
  FormFieldOptionsSwitcher,
  BaseButton,
} from "@tager/admin-ui";
import { useToast } from "@tager/admin-services";

import { updateUserBalance } from "@/modules/users";

type FormValues = {
  sum: number | null;
  message: string;
  type: string;
};

const typeOptions = [
  {
    value: "PLUS",
    label: "Начислить",
    activeColor: "#d2edd2",
  },
  {
    value: "MINUS",
    label: "Списать",
    activeColor: "#edd8d8",
  },
];

const INITIAL_VALUE = {
  sum: null,
  message: "",
  type: typeOptions[0].value,
};

export default defineComponent({
  name: "UsersEditBalanceForm",
  components: {
    FormField,
    FormFieldOptionsSwitcher,
    BaseButton,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  emits: ["submit"],
  setup(props, context) {
    const toast = useToast();

    const values = ref<FormValues>({ ...INITIAL_VALUE });

    const loading = ref<boolean>(false);

    const isDisabled = computed<boolean>(
      () =>
        !(values.value && values.value?.sum && values.value?.sum > 0) ||
        values.value.message.trim().length === 0
    );

    const onSubmit = async (e: SubmitEvent) => {
      e.preventDefault();

      try {
        loading.value = true;

        const userBalance = await updateUserBalance(props.id, {
          message: values.value.message,
          sum:
            (values.value.sum || 0) * (values.value.type === "MINUS" ? -1 : 1),
        });

        values.value = { ...INITIAL_VALUE };

        context.emit("submit", {
          balance: userBalance.data.balance,
        });

        toast.show({
          variant: "success",
          title: "Успешно",
          body: "Баланс успешно изменен",
        });
      } catch (e) {
        toast.show({
          variant: "danger",
          title: "Ошибка",
          body: "Ошибка изменения баланса",
        });
      } finally {
        loading.value = false;
      }
    };

    return {
      values,
      loading,
      typeOptions,
      isDisabled,
      onSubmit,
    };
  },
});
