import { OutgoingValueUnion } from "@tager/admin-dynamic-field";
import {
  MoveDirection,
  Nullable,
  request,
  ResponseBody,
} from "@tager/admin-services";

import {
  FullTimetableType,
  TrainingType,
  TimetableOrderType,
  StatsType,
  TimetableStatsType,
  ServiceCategoryType,
  FullServiceCategoryType,
  FullAdditionalServiceCategoryType,
  FullSecondaryServiceType,
  SecondaryServiceType,
  AdditionalServiceCategoryType,
  CompanyType,
  FullCompanyType,
  TimetableWorkingDaysItem,
  PromocodeType,
  PromocodeFullType,
} from "@/types";

export type CountData = { count: number };
type SuccessData = { success: boolean };

/** Training */

export function getTrainingCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/trainings/count" });
}

export function getTrainingList(): Promise<ResponseBody<Array<TrainingType>>> {
  return request.get({ path: "/admin/trainings" });
}

export function getTraining(
  trainingId: string | number
): Promise<ResponseBody<TrainingType>> {
  return request.get({ path: `/admin/trainings/${trainingId}` });
}

export type TrainingCreationPayload = {
  readonly dateStart: string;
  readonly dateEnd: string;
  readonly name: string;
  readonly phone: string;
  readonly email: string;
};

export function createTraining(
  payload: TrainingCreationPayload
): Promise<ResponseBody<TrainingType>> {
  return request.post({ path: "/admin/trainings", body: payload });
}

export type TrainingUpdatePayload = TrainingCreationPayload;

export function updateTraining(
  trainingId: string | number,
  payload: TrainingUpdatePayload
): Promise<ResponseBody<TrainingType>> {
  return request.put({
    path: `/admin/trainings/${trainingId}`,
    body: payload,
  });
}

export function deleteTraining(
  trainingId: string | number
): Promise<SuccessData> {
  return request.delete({
    path: `/admin/trainings/${trainingId}`,
  });
}

export function sendTraining(
  trainingId: string | number
): Promise<SuccessData> {
  return request.post({
    path: `/admin/trainings/${trainingId}/send`,
  });
}

/** Timetable */

export function getTimetableList(
  dateFrom: string,
  dateTo: string,
  city: number
): Promise<ResponseBody<Array<FullTimetableType>>> {
  return request.get({
    path: "/admin/workers/timetable",
    params: { dateFrom, dateTo, city },
  });
}

export function getTimetableWorkingDays(
  dateStart: string,
  dateEnd: string,
  city: number
): Promise<ResponseBody<Array<TimetableWorkingDaysItem>>> {
  return request.get({
    path: "/admin/workers/timetable/working-days",
    params: { dateStart, dateEnd, city },
  });
}

export function setWorkingDays(
  workerId: string | number,
  payload: { value: number }
): Promise<SuccessData> {
  return request.post({
    path: `/admin/workers/${workerId}/set-working-days`,
    body: payload,
  });
}

export function setTimetableInfo(
  workerId: string | number,
  payload: { text: string }
): Promise<SuccessData> {
  return request.post({
    path: `/admin/workers/${workerId}/timetable-info`,
    body: payload,
  });
}

export function getTimetableOrderList(
  dateFrom: string,
  dateTo: string,
  city: number
): Promise<ResponseBody<Array<TimetableOrderType>>> {
  return request.get({
    path: "/admin/workers/timetable/orders",
    params: { dateFrom, dateTo, city },
  });
}

export function getTimetableStats(
  dateFrom: string,
  dateTo: string,
  city: number
): Promise<ResponseBody<Array<TimetableStatsType>>> {
  return request.get({
    path: "/admin/workers/timetable/stats",
    params: { dateFrom, dateTo, city },
  });
}

export function getStatisticList(
  dateFrom: string,
  dateTo: string,
  city?: number,
  params?: {
    pageNumber?: number;
    pageSize?: number;
  }
): Promise<ResponseBody<Array<StatsType>>> {
  return request.get({
    path: "/admin/workers/stats",
    params: { dateFrom, dateTo, city, ...params },
  });
}

export function setWorkingDay(
  workerId: string | number,
  payload: { date: string; value: { startAt: string; finishAt: string } | null }
): Promise<SuccessData> {
  return request.post({
    path: `/admin/workers/${workerId}/timetable`,
    body: payload,
  });
}

/** Orders */

/** Calendar */

/** Service Category */

export function getServiceCategoryList(): Promise<
  ResponseBody<Array<ServiceCategoryType>>
> {
  return request.get({ path: "/admin/services/categories" });
}

export function getServiceCategory(
  serviceCategoryId: string | number
): Promise<ResponseBody<FullServiceCategoryType>> {
  return request.get({
    path: `/admin/services/categories/${serviceCategoryId}`,
  });
}

export function deleteServiceCategory(
  serviceCategoryId: string | number
): Promise<SuccessData> {
  return request.delete({
    path: `/admin/services/categories/${serviceCategoryId}`,
  });
}

export type ServiceCategoryCreationPayload = {
  readonly name: string;
  readonly systemName: string;
  readonly formLabel: string;
  readonly emptyValueLabel: string;
};

export function createServiceCategory(
  payload: ServiceCategoryCreationPayload
): Promise<ResponseBody<FullServiceCategoryType>> {
  return request.post({ path: "/admin/services/categories", body: payload });
}

export type ServiceCategoryUpdatePayload = ServiceCategoryCreationPayload;

export function updateServiceCategory(
  serviceCategoryId: string | number,
  payload: ServiceCategoryUpdatePayload
): Promise<ResponseBody<FullServiceCategoryType>> {
  return request.put({
    path: `/admin/services/categories/${serviceCategoryId}`,
    body: payload,
  });
}

export function getServicesCount(
  type: string
): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/services/count?type=" + type });
}

/** Additional Service  */
export function deleteAdditionalService(
  additionalServiceId: string | number
): Promise<SuccessData> {
  return request.delete({
    path: `/admin/services/${additionalServiceId}`,
  });
}

export function moveAdditionalService(
  additionalServiceId: string | number,
  direction: MoveDirection
): Promise<SuccessData> {
  return request.post({
    path: `/admin/services/${additionalServiceId}/move/${direction}`,
  });
}

/** Additional Service Category */

export function getAdditionalServiceCategoryList(
  serviceCategoryId: string | number
): Promise<ResponseBody<Array<AdditionalServiceCategoryType>>> {
  return request.get({
    path: `/admin/services/by-category/${serviceCategoryId}`,
  });
}

export function getAdditionalServiceCategory(
  additionalServiceId: string | number
): Promise<ResponseBody<FullAdditionalServiceCategoryType>> {
  return request.get({
    path: `/admin/services/${additionalServiceId}`,
  });
}

export type AdditionalServiceCategoryCreationPayload = {
  readonly name: string;
  readonly quantity: number;
  readonly price: number;
  readonly minutes: number;
  readonly categoryId: number;
  readonly fullWorkerPrice: number;
  readonly rewardPrimaryOrder: Array<{
    city: number;
    reward: number | null;
  }>;
};

export function createAdditionalServiceCategory(
  payload: AdditionalServiceCategoryCreationPayload
): Promise<ResponseBody<FullAdditionalServiceCategoryType>> {
  return request.post({
    path: "/admin/services",
    params: { type: "PRIMARY" },
    body: payload,
  });
}

export type AdditionalServiceCategoryUpdatePayload =
  AdditionalServiceCategoryCreationPayload;

export function updateAdditionalServiceCategory(
  additionalServiceId: string | number,
  payload: AdditionalServiceCategoryUpdatePayload
): Promise<ResponseBody<FullAdditionalServiceCategoryType>> {
  return request.put({
    path: `/admin/services/${additionalServiceId}`,
    body: payload,
  });
}

/** Secondary Service */

export function getSecondaryServiceList(): Promise<
  ResponseBody<Array<SecondaryServiceType>>
> {
  return request.get({
    path: `/admin/services/secondary`,
  });
}

export function getSecondaryService(
  additionalServiceId: string | number
): Promise<ResponseBody<FullSecondaryServiceType>> {
  return request.get({
    path: `/admin/services/${additionalServiceId}`,
  });
}

export type SecondaryServiceCreationPayload = {
  readonly name: string;
  readonly mode: Nullable<string>;
  readonly price: number;
  readonly fullWorkerPrice: number;
  readonly step: number;
  readonly minutes: number;
  readonly tooltip: string;
  readonly isNew: boolean;
  readonly isFinalPrice: boolean;
  readonly hidden: boolean;
  readonly icon: Nullable<string>;
  readonly iconActive: Nullable<string>;
};

export function createSecondaryService(
  payload: SecondaryServiceCreationPayload
): Promise<ResponseBody<FullSecondaryServiceType>> {
  return request.post({
    path: "/admin/services",
    params: { type: "SECONDARY" },
    body: payload,
  });
}

export type SecondaryServiceUpdatePayload = SecondaryServiceCreationPayload;

export function updateSecondaryService(
  additionalServiceId: string | number,
  payload: SecondaryServiceUpdatePayload
): Promise<ResponseBody<FullSecondaryServiceType>> {
  return request.put({
    path: `/admin/services/${additionalServiceId}`,
    body: payload,
  });
}

/** Companies */

export function getCompanyList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<CompanyType>>> {
  return request.get({ path: "/admin/companies", params });
}

export function getCompany(
  companyId: string | number
): Promise<ResponseBody<FullCompanyType>> {
  return request.get({ path: `/admin/companies/${companyId}` });
}

export type CompanyCreationPayload = {
  name: string;
  fullName: string;
  headName: string;
  headNameParentCase: string;
  fromEmail: string;
  fromName: string;
};

export function createCompany(
  payload: CompanyCreationPayload
): Promise<ResponseBody<FullCompanyType>> {
  return request.post({ path: "/admin/companies", body: payload });
}

export type CompanyUpdatePayload = CompanyCreationPayload;

export function updateCompany(
  companyId: string | number,
  payload: CompanyUpdatePayload
): Promise<ResponseBody<FullCompanyType>> {
  return request.put({
    path: `/admin/companies/${companyId}`,
    body: payload,
  });
}

export function deleteCompany(
  companyId: string | number
): Promise<SuccessData> {
  return request.delete({
    path: `/admin/companies/${companyId}`,
  });
}

/** Promocodes */
export function getPromocodeCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/promocodes/count" });
}

export function getPromocodeList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<PromocodeType>>> {
  return request.get({ path: "/admin/promocodes", params });
}

export function getPromocode(
  promocodeId: string | number
): Promise<ResponseBody<PromocodeFullType>> {
  return request.get({
    path: `/admin/promocodes/${promocodeId}`,
  });
}

export type PromocodeCreationPayload = {
  code: string;
  discount: number;
  dateStart: string;
  dateEnd: Nullable<string>;
  dates: Nullable<OutgoingValueUnion>;
  emails: Nullable<OutgoingValueUnion>;
  reusable: boolean;
};
export type PromocodeUpdatePayload = PromocodeCreationPayload;

export function createPromocode(
  payload: PromocodeCreationPayload
): Promise<ResponseBody<PromocodeFullType>> {
  return request.post({ path: "/admin/promocodes", body: payload });
}

export function updatePromocode(
  promocodeId: string | number,
  payload: PromocodeUpdatePayload
): Promise<ResponseBody<PromocodeFullType>> {
  return request.put({
    path: `/admin/promocodes/${promocodeId}`,
    body: payload,
  });
}

export function deletePromocode(
  promocodeId: string | number
): Promise<SuccessData> {
  return request.delete({
    path: `/admin/promocodes/${promocodeId}`,
  });
}

export function getSubscriptionCount(
  city?: number
): Promise<ResponseBody<CountData>> {
  const query = city ? { city } : {};
  return request.get({ path: "/admin/subscriptions/count", params: query });
}
