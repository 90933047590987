import { RouteRecordRaw } from "vue-router";

import { LINKS } from "@/router/links";
import { ROUTE_PATHS } from "@/router/paths";
import AddressesEdit from "@/modules/users/addresses-edit/AddressesEdit.vue";

import UsersList from "./users-list/UsersList.vue";
import UsersCreate from "./users-create/UsersCreate.vue";
import UsersEdit from "./users-edit/UsersEdit.vue";

export const USERS_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.USERS_LIST,
  component: UsersList,
  name: "Список пользователей",
  meta: {
    getBreadcrumbs: () => [LINKS.HOME, LINKS.USERS_LIST],
  },
};

export const USERS_CREATE_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.USERS_CREATE,
  component: UsersCreate,
  name: "Новый клиент",
  meta: {
    getBreadcrumbs: () => [LINKS.HOME, LINKS.USERS_LIST],
  },
};

export const USERS_EDIT_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.USERS_EDIT,
  component: UsersEdit,
  name: "Профиль клиента",
  meta: {
    getBreadcrumbs: () => [LINKS.HOME, LINKS.USERS_LIST],
  },
};

export const ADDRESSES_EDIT_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.ADDRESSES_EDIT,
  component: AddressesEdit,
  name: "Профиль адреса",
  meta: {
    getBreadcrumbs: () => [LINKS.HOME, LINKS.USERS_LIST],
  },
};
