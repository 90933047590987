
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  FormFooter,
  OptionType,
  TabType,
  TagerFormSubmitEvent,
} from "@tager/admin-ui";
import {
  navigateBack,
  Nullable,
  useResource,
  useToast,
  useUserPermission,
} from "@tager/admin-services";
import { Page } from "@tager/admin-layout";

import { getWebsiteOrigin } from "@/utils/common";
import { useFetchServices } from "@/modules/orders/hooks/useFetchServices";
import {
  OrderFullInterface,
  SecondaryServiceValue,
} from "@/modules/orders/typings";
import {
  changeOrderServices,
  getOrder,
  setOrderPrice,
} from "@/modules/orders/services";
import SelectServices from "@/modules/orders/components/SelectServices/SelectServices.vue";
import { getActiveOrdersListUrl, getOrdersViewUrl } from "@/modules/orders";
import OrderFooterInfo from "@/modules/orders/components/OrderFooterInfo.vue";
import OrdersEditTopRight from "@/modules/orders/views/orders-edit/containers/OrdersEditTopRight.vue";
import OrdersEditPrice from "@/modules/orders/views/orders-edit/containers/OrdersEditPrice/OrdersEditPrice.vue";
import OrdersEditWorkers from "@/modules/orders/views/orders-edit/containers/OrdersEditWorkers/OrdersEditWorkers.vue";
import OrdersEditStatusBadge from "@/modules/orders/containers/OrdersEditStatusBadge.vue";
import OrdersEditModeSwitcher from "@/modules/orders/containers/OrdersEditModeSwitcher.vue";
import { Scope } from "@/rbac/Scope";
import {
  OrderTariff,
  OrderTariffOptions,
} from "@/modules/orders/enums/OrderTariff";

import OrdersEditCommon from "./components/OrdersEditCommon.vue";
import OrdersEditClient from "./containers/OrdersEditClient";

export type PriceFormValues = {
  isFree: boolean;
  price: number;
  discount: number;
  coefficient: number;
  tariff: OptionType<OrderTariff> | null;
};

export default defineComponent({
  name: "OrdersEdit",
  components: {
    OrdersEditModeSwitcher,
    OrdersEditStatusBadge,
    OrdersEditPrice,
    OrdersEditTopRight,
    OrderFooterInfo,
    Page,
    FormFooter,
    OrdersEditCommon,
    SelectServices,
    OrdersEditClient,
    OrdersEditWorkers,
  },
  setup() {
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();

    const isSubmitting = ref<boolean>(false);

    const orderId = computed<number>(() => +route.params.orderId);

    const canEditOrder = useUserPermission(Scope.OrdersUpdate);
    if (!canEditOrder.value) {
      router.replace(getOrdersViewUrl(orderId.value));
    }

    const primaryServicesValue = ref<number[]>([]);
    const secondaryServicesValue = ref<SecondaryServiceValue[]>([]);
    const deliveryKeysAddress = ref<string>();

    const priceValue = ref<PriceFormValues>();

    const onServicesChange = (value: Record<string, any>) => {
      primaryServicesValue.value = value?.primary || [];
      secondaryServicesValue.value = value?.secondary || [];
      deliveryKeysAddress.value = value?.deliveryKeysAddress || "";
    };

    const onServicesChangeClick = () => {
      selectedTabId.value = "services";
    };

    const {
      primary: primaryServices,
      secondary: secondaryServices,
      loading: servicesLoading,
    } = useFetchServices();

    const [
      fetchOrder,
      {
        data: order,
        loading: isOrderLoading,
        error: orderLoadingError,
        errorMessage: orderLoadingMessageError,
        status: orderLoadingStatus,
      },
    ] = useResource<Nullable<OrderFullInterface>>({
      fetchResource: () => {
        return orderId.value && !isNaN(orderId.value)
          ? getOrder(orderId.value)
          : new Promise((resolve) => resolve({ data: null }));
      },
      initialValue: null,
      resourceName: "Загрузка заказа",
    });

    watch([order], () => {
      primaryServicesValue.value = order.value?.services.primary || [];
      secondaryServicesValue.value = order.value?.services.secondary || [];
      deliveryKeysAddress.value =
        order.value?.services.keysDeliveryAddress || "";

      priceValue.value = {
        isFree: order.value?.price.isFree || false,
        price: order.value?.price.base || 0,
        discount: order.value?.price.discount || 0,
        coefficient: order.value?.price.coefficient || 0,
        tariff:
          OrderTariffOptions.find(
            (item) => item.value === order.value?.price.tariff
          ) || null,
      };
    });

    onMounted(() => fetchOrder());
    watch([orderId], () => fetchOrder());

    const isContentLoading = computed<boolean>(
      () => isOrderLoading.value || servicesLoading.value
    );

    const tabList: Array<TabType> = [
      { id: "common", label: "Основное" },
      { id: "services", label: "Услуги" },
      { id: "price", label: "Цена" },
      { id: "workers", label: "Клинеры" },
      { id: "user", label: "Клиент" },
    ];

    const selectedTabId = ref<string>(
      tabList[process.env.VUE_APP_ENV === "local" ? 0 : 0].id
    );

    const onSaveClick = async (event: TagerFormSubmitEvent) => {
      if (selectedTabId.value === "services") {
        return onServicesClick(event);
      }
      if (selectedTabId.value === "price") {
        return onPriceSave(event);
      }
    };

    const onPriceSave = async (event: TagerFormSubmitEvent) => {
      isSubmitting.value = true;

      if (!priceValue.value) return;

      try {
        await setOrderPrice(orderId.value, {
          isFree: priceValue.value.isFree,
          price: priceValue.value.price,
          discount: priceValue.value.discount,
          coefficient: priceValue.value.coefficient,
          tariff: priceValue.value.tariff?.value || null,
        });

        if (event.type === "save_exit") {
          navigateBack(router, getActiveOrdersListUrl());
        } else {
          fetchOrder();
        }

        toast.show({
          variant: "success",
          title: "Успешно",
          body: "Цена успешно изменена",
        });
      } catch (error) {
        console.error(error);

        toast.show({
          variant: "danger",
          title: "Ошибка",
          body: "Ошибка изменения цены",
        });
      } finally {
        isSubmitting.value = false;
      }
    };

    const onServicesClick = async (event: TagerFormSubmitEvent) => {
      isSubmitting.value = true;

      try {
        await changeOrderServices(
          orderId.value,
          primaryServicesValue.value,
          secondaryServicesValue.value,
          deliveryKeysAddress.value || null
        );

        if (event.type === "save_exit") {
          navigateBack(router, getActiveOrdersListUrl());
        } else {
          selectedTabId.value = "common";
          fetchOrder();
        }

        toast.show({
          variant: "success",
          title: "Успешно",
          body: "Услуги успешно изменены",
        });
      } catch (error) {
        console.error(error);

        toast.show({
          variant: "danger",
          title: "Ошибка",
          body: "Ошибка сохранения услуг",
        });
      } finally {
        isSubmitting.value = false;
      }
    };

    const onUpdate = () => fetchOrder();

    return {
      getWebsiteOrigin,
      orderId,
      isContentLoading,
      orderLoadingError,
      orderLoadingMessageError,
      tabList,
      selectedTabId,
      orderLoadingStatus,

      primaryServices,
      primaryServicesValue,
      secondaryServices,
      secondaryServicesValue,
      deliveryKeysAddress,

      onServicesChangeClick,

      order,
      onServicesChange,
      isSubmitting,
      onSubmit: onSaveClick,
      onUpdate,

      priceValue,
    };
  },
});
