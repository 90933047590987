import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3989d073"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "type-field" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_FormFieldSelect = _resolveComponent("FormFieldSelect")!
  const _component_SelectServices = _resolveComponent("SelectServices")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Spinner, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_FormFieldSelect, {
              value: _ctx.typeValue,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.typeValue) = $event)),
              label: "Тип заказа",
              name: "orderType",
              searchable: false,
              options: _ctx.orderTypeOptions
            }, null, 8, ["value", "options"])
          ]),
          (_ctx.typeValue)
            ? (_openBlock(), _createBlock(_component_SelectServices, {
                key: 0,
                type: _ctx.typeValue?.value,
                primary: _ctx.primary,
                secondary: _ctx.secondary,
                onChange: _ctx.onServicesChange
              }, null, 8, ["type", "primary", "secondary", "onChange"]))
            : _createCommentVNode("", true)
        ]))
  ]))
}