
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import { Page } from "@tager/admin-layout";
import {
  convertRequestErrorToMap,
  navigateBack,
  Nullable,
  useResource,
  useToast,
} from "@tager/admin-services";
import {
  FormField,
  FormFieldSelect,
  FormFooter,
  TagerFormSubmitEvent,
} from "@tager/admin-ui";

import {
  getAddressesUpdateUrl,
  getUserAddress,
  getUsersUpdateUrl,
  updateAddress,
} from "@/modules/users";
import { useCities } from "@/modules/cities";
import {
  AddressFormValues,
  convertAddressFormValuesToUpdatePayload,
  getInitialAddressFormValues,
} from "@/modules/users/addresses-edit/AddressesEdit.helpers";
import { UserAddressFullModel } from "@/modules/users/typings";
import { addressTypeOptions } from "@/enums/AddressType";

export default defineComponent({
  name: "AddressesEdit",
  components: {
    FormFieldSelect,
    FormField,
    FormFooter,
    Page,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const id = computed<number>(() => +route.params.addressId);
    const { options: citiesOptions } = useCities();

    const errors = ref<Record<string, string>>({});
    const isSubmitting = ref<boolean>(false);
    const values = ref<AddressFormValues>();

    const [fetchModel, { data: model, loading: isModelLoading }] = useResource<
      Nullable<UserAddressFullModel>
    >({
      fetchResource: () =>
        id.value
          ? getUserAddress(id.value)
          : new Promise((resolve) => resolve({ data: null })),
      initialValue: null,
      resourceName: "Address",
    });

    onMounted(fetchModel);
    watch([id], fetchModel);
    watch(
      [model, citiesOptions],
      () =>
        model.value &&
        (values.value = getInitialAddressFormValues(
          model.value,
          citiesOptions.value
        ))
    );

    const pageTitle = computed<string>(() => {
      return `Редактировать адрес ${id.value}`;
    });

    const backButtonUrl = computed<string>(() => {
      return model.value ? getUsersUpdateUrl(model.value?.user.id) : "";
    });

    const submitForm = async (event: TagerFormSubmitEvent) => {
      if (!values.value) return;

      isSubmitting.value = true;
      errors.value = {};

      const requestPromise = updateAddress(
        id.value,
        convertAddressFormValuesToUpdatePayload(values.value)
      );

      try {
        await requestPromise;

        if (event.type === "save_exit") {
          navigateBack(router, backButtonUrl.value);
        }

        toast.show({
          variant: "success",
          title: "Success",
          body: "Адрес успешно сохранен",
        });
      } catch (error: any) {
        errors.value = convertRequestErrorToMap(error);
        toast.show({
          variant: "danger",
          title: "Ошибка",
          body: "Ошибка сохранения адреса",
        });
      } finally {
        isSubmitting.value = false;
      }
    };

    const isHouseSelected = computed(
      () => values.value?.type.value === "HOUSE"
    );

    return {
      model,
      errors,
      values,
      backButtonUrl,
      pageTitle,
      isLoading: isModelLoading,
      isSubmitting,
      submitForm,
      getAddressesUpdateUrl,
      citiesOptions,
      typeOptions: addressTypeOptions,
      isHouseSelected,
    };
  },
});
