import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldValue = _resolveComponent("FieldValue")!
  const _component_UsersEditBalanceForm = _resolveComponent("UsersEditBalanceForm")!
  const _component_ToggleSection = _resolveComponent("ToggleSection")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_FieldValue, {
      label: "Баланс",
      value: String(_ctx.balanceValue.toFixed(2)) + ' руб.'
    }, null, 8, ["value"]),
    _createVNode(_component_ToggleSection, { label: "Изменение баланса" }, {
      default: _withCtx(() => [
        _createVNode(_component_UsersEditBalanceForm, {
          id: _ctx.id,
          onSubmit: _ctx.onBalanceSubmit
        }, null, 8, ["id", "onSubmit"])
      ]),
      _: 1
    }),
    _createVNode(_component_DataTable, {
      "column-defs": _ctx.columnDefs,
      "row-data": _ctx.rowData,
      "row-css-class": _ctx.rowCssClass,
      loading: _ctx.isRowDataLoading,
      "error-message": _ctx.errorMessage,
      "use-search": false
    }, {
      "cell(balance)": _withCtx(({ rowIndex }) => [
        _createTextVNode(_toDisplayString(_ctx.getRowBalance(rowIndex).toFixed(2)), 1)
      ]),
      _: 1
    }, 8, ["column-defs", "row-data", "row-css-class", "loading", "error-message"])
  ], 64))
}