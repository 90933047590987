import { OptionType, TabType } from "@tager/admin-ui";

import { UserFullInterface, UserUpdatePayload } from "@/modules/users/typings";

export type FormValues = {
  name: string;
  phone: string;
  email: string;
  description: string;
  infoForWorkers: string;

  likeWorkers: Array<OptionType<number>>;
  dislikeWorkers: Array<OptionType<number>>;

  allowExportEmail: boolean;
  allowExportPhone: boolean;
};

export const getInitialFormValues = (
  user: UserFullInterface | null,
  workers: Array<OptionType<number>>
): FormValues => {
  if (!user) {
    return {
      name: "",
      phone: "",
      email: "",
      description: "",
      infoForWorkers: "",
      likeWorkers: [],
      dislikeWorkers: [],
      allowExportPhone: false,
      allowExportEmail: false,
    };
  }

  return {
    name: user.name,
    phone: user.phone,
    email: user.email,
    description: user.description,
    infoForWorkers: user.infoForWorkers,
    likeWorkers: workers.filter((item) =>
      user.likeWorkers.includes(item.value)
    ),
    dislikeWorkers: workers.filter((item) =>
      user.dislikeWorkers.includes(item.value)
    ),
    allowExportEmail: user.allowExportEmail,
    allowExportPhone: user.allowExportPhone,
  };
};

export function convertFormValuesToUpdateUserPayload(
  values: FormValues
): UserUpdatePayload {
  return {
    name: values.name,
    phone: values.phone,
    email: values.email,
    description: values.description,
    infoForWorkers: values.infoForWorkers,
    likeWorkers: values.likeWorkers.map((item) => item.value),
    dislikeWorkers: values.dislikeWorkers.map((item) => item.value),
    allowExportEmail: values.allowExportEmail,
    allowExportPhone: values.allowExportPhone,
  };
}

export const USERS_EDIT_TABS: Array<TabType> = [
  { id: "common", label: "Основное" },
  { id: "orders", label: "Заказы" },
  { id: "addresses", label: "Адреса" },
  { id: "balance", label: "Баланс" },
  { id: "devices", label: "Устройства" },
];
