import { Nullable } from "@tager/admin-services";

import { AddressFields, AddressFormFields } from "@/modules/orders/typings";
import { AddressType, addressTypeOptions } from "@/enums/AddressType";

export type PromoCode = {
  code: string;
  discount: number;
};

export type FindUserResponse = {
  id: number;
  name: string;
  phone: string;
};

export type CheckDateResponse = Array<{
  city: {
    id: number;
    name: string;
  };
  available: boolean;
  closed: boolean;
  closedTimeSlots: string[];
  coefficient: number;
}>;

export type UserAddress = {
  id: number;
  city: {
    id: number;
    name: string;
  };
  type: AddressType;
  name: string;
  street: string;
  house: Nullable<number>;
  apartment: Nullable<string>;
  wing: Nullable<string>;
  entrance: Nullable<string>;
  intercomCode: Nullable<string>;
  floor: Nullable<number>;
};

export type PrimaryService = {
  id: number;
  name: string;
  price: number;
  minutes: number;
};

export type PrimaryServiceCategory = {
  id: number;
  name: string;
  systemName: string;
  services: Array<PrimaryService>;
};

export type CheckPromoResponse = {
  error: Nullable<string>;
  code: Nullable<string>;
  discount: Nullable<number>;
};

export type SecondaryServiceMode =
  | "QUANTITY"
  | "TRUE_FALSE"
  | "TIME"
  | "DELIVERY_KEYS";

export type SecondaryService = {
  id: number;
  mode: SecondaryServiceMode;
  step: number;
  name: string;
  price: number;
  minutes: number;
};

export const defaultAddressValues: AddressFormFields = {
  id: null,
  type: addressTypeOptions[0],
  city: null,
  street: null,
  house: null,
  wing: null,
  apartment: null,
  floor: null,
  entrance: null,
  intercomCode: null,
};

export type CustomUserData = {
  phone: string;
  name: string;
};

export type CreateOrderRequest = {
  userId: Nullable<number>;
  userNew: Nullable<CustomUserData>;
  address: AddressFields;
  datetime: string;
  period: string;
  primaryServices: number[];
  secondaryServices: Array<{
    id: number;
    value: number | boolean;
  }>;
  promoCode: Nullable<string>;
  comment: Nullable<string>;
  keysDeliveryAddress: Nullable<string>;
};

export interface OrderFullInterface {
  id: number;
}
