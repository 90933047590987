import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "row-cols-2" }
const _hoisted_2 = { class: "row-cols-2" }
const _hoisted_3 = {
  key: 0,
  class: "row-cols-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormFieldSelect = _resolveComponent("FormFieldSelect")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: _ctx.pageTitle,
    "is-content-loading": _ctx.isLoading
  }, _createSlots({ _: 2 }, [
    (!_ctx.isLoading && !!_ctx.values)
      ? {
          name: "content",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_FormFieldSelect, {
                value: _ctx.values.type,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.type) = $event)),
                label: "Тип жилья",
                name: "type",
                searchable: false,
                "no-error-padding": true,
                clearable: false,
                options: _ctx.typeOptions
              }, null, 8, ["value", "options"]),
              _createVNode(_component_FormFieldSelect, {
                value: _ctx.values.city,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.city) = $event)),
                label: "Город",
                name: "city",
                searchable: false,
                clearable: false,
                options: _ctx.citiesOptions
              }, null, 8, ["value", "options"])
            ]),
            _createVNode(_component_FormField, {
              value: _ctx.values.street,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.values.street) = $event)),
              name: "street",
              error: _ctx.errors.street,
              label: "Улица"
            }, null, 8, ["value", "error"]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_FormField, {
                value: _ctx.values.house,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.values.house) = $event)),
                name: "house",
                error: _ctx.errors.house,
                label: "Номер дома"
              }, null, 8, ["value", "error"]),
              (!_ctx.isHouseSelected)
                ? (_openBlock(), _createBlock(_component_FormField, {
                    key: 0,
                    value: _ctx.values.wing,
                    "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.values.wing) = $event)),
                    name: "wing",
                    error: _ctx.errors.wing,
                    label: "Корпус"
                  }, null, 8, ["value", "error"]))
                : _createCommentVNode("", true)
            ]),
            (!_ctx.isHouseSelected)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_FormField, {
                    value: _ctx.values.entrance,
                    "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.values.entrance) = $event)),
                    name: "entrance",
                    error: _ctx.errors.entrance,
                    label: "Подъезд"
                  }, null, 8, ["value", "error"]),
                  _createVNode(_component_FormField, {
                    value: _ctx.values.intercomCode,
                    "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.values.intercomCode) = $event)),
                    name: "intercomCode",
                    error: _ctx.errors.intercomCode,
                    label: "Код от подъезда"
                  }, null, 8, ["value", "error"]),
                  _createVNode(_component_FormField, {
                    value: _ctx.values.floor,
                    "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.values.floor) = $event)),
                    name: "floor",
                    error: _ctx.errors.floor,
                    label: "Этаж",
                    type: "number"
                  }, null, 8, ["value", "error"]),
                  _createVNode(_component_FormField, {
                    value: _ctx.values.apartment,
                    "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.values.apartment) = $event)),
                    name: "apartment",
                    error: _ctx.errors.apartment,
                    label: "Квартира"
                  }, null, 8, ["value", "error"])
                ]))
              : _createCommentVNode("", true)
          ]),
          key: "0"
        }
      : undefined,
    (!_ctx.isLoading)
      ? {
          name: "footer",
          fn: _withCtx(() => [
            _createVNode(_component_FormFooter, {
              "back-href": _ctx.backButtonUrl,
              "is-submitting": _ctx.isSubmitting,
              "is-creation": _ctx.isCreation,
              "can-create-another": _ctx.isCreation,
              onSubmit: _ctx.submitForm
            }, null, 8, ["back-href", "is-submitting", "is-creation", "can-create-another", "onSubmit"])
          ]),
          key: "1"
        }
      : undefined
  ]), 1032, ["title", "is-content-loading"]))
}