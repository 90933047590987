
import { defineComponent, PropType, ref } from "vue";

import { FormField, FormFieldSelect } from "@tager/admin-ui";

import { AddressFormFields } from "@/modules/orders/typings";
import { useCities } from "@/modules/cities";
import { AddressType, addressTypeOptions } from "@/enums/AddressType";

export default defineComponent({
  name: "AddressForm",
  components: {
    FormField,
    FormFieldSelect,
  },
  computed: {
    AddressType() {
      return AddressType;
    },
  },
  props: {
    disabled: Boolean,
    value: { type: Object as PropType<AddressFormFields>, required: true },
  },
  emits: ["change"],

  setup(props, { emit }) {
    const { options: citiesOptions } = useCities();

    const valueRef = ref<AddressFormFields>(props.value);

    const onChange = () => {
      setTimeout(() => {
        emit("change", valueRef.value);
      });
    };

    return {
      valueRef,
      citiesOptions,
      onChange,
      houseTypeOptions: addressTypeOptions,
    };
  },
});
