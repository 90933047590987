import { Nullable } from "@tager/admin-services";
import { OptionType } from "@tager/admin-ui";

import { AddressType } from "@/enums/AddressType";
import { OrderSource } from "@/enums/OrderSource";
import { OrderTariff } from "@/modules/orders/enums/OrderTariff";

export type SecondaryServiceValue = {
  id: number;
  value: number | boolean;
};

export type OrderType = "WINDOWS" | "ROOMS";
export type OrderStatus = "CANCELLED" | "COMPLETED" | "WAITING";

export type OrderSubscriptionPeriod =
  | "EVERY_WEEK"
  | "EVERY_2_WEEKS"
  | "EVERY_MONTH";

export function subscriptionPeriodLabel(
  period: OrderSubscriptionPeriod
): string {
  switch (period) {
    case "EVERY_WEEK":
      return "Еженедельно";
    case "EVERY_2_WEEKS":
      return "Раз в 2 недели";
    case "EVERY_MONTH":
      return "Ежемесячно";
    default:
      return "";
  }
}

export type OrderPromocode = {
  id: number;
  code: string;
  discount: number;
};

export type AddressFormFields = {
  id: number | null;
  type: OptionType<AddressType>;
  city: Nullable<OptionType<number>>;
  street: Nullable<string>;
  house: Nullable<number>;
  apartment: Nullable<string>;
  wing: Nullable<string>;
  entrance: Nullable<string>;
  intercomCode: Nullable<string>;
  floor: Nullable<number>;
};

export type AddressFields = {
  type: AddressType;
  city: Nullable<number>;
  street: Nullable<string>;
  house: Nullable<number>;
  apartment: Nullable<string>;
  wing: Nullable<string>;
  entrance: Nullable<string>;
  intercomCode: Nullable<string>;
  floor: Nullable<number>;
};

export type OrderAvailableWorker = {
  id: number;
  number: number;
  name: string;
  availablePylesos: boolean;
  availableWindows: boolean;
  userMode: "GOOD" | "BAD" | null;
  workingTime: null | [string, string];
  isOutOfWorkingTime: boolean;
  orders: Array<{
    id: number;
    start: string;
    end: string;
  }>;
  distance: number;
};

export type OrderWorker = {
  id: number;
  number: number;
  name: string;
  distance: number;
  time: number;
  additionalWorkTime: number;
  rate: number;
  fine: number;
  pylesos: boolean;
  keys: boolean;
  reward: number;
};

export type OrderListItemWorker = {
  id: number;
  number: number;
  name: string;
  reward: number;
  distance: number;
  isArchived: boolean;
  latitude: number;
  longitude: number;
};

export type SubscriptionPeriod = "EVERY_WEEK" | "EVERY_2_WEEKS" | "EVERY_MONTH";

export function getSubscriptionPeriodLabel(period: SubscriptionPeriod): string {
  switch (period) {
    case "EVERY_WEEK":
      return "Раз в неделю";
    case "EVERY_2_WEEKS":
      return "Раз в 2 недели";
    case "EVERY_MONTH":
      return "Раз в месяц";
    default:
      return "Неизвестный";
  }
}

export type OrderListItemSubscription = {
  id: number;
  mode: SubscriptionPeriod;
  createdAt: string;
  index: number;
};

export type OrderInterface = {
  id: number;
  source: OrderSource | null;
  tariff: OrderTariff | null;
  datetime: string;
  createdAt: string;
  smsSent: boolean;
  type: AddressType;

  accepted: boolean;
  acceptedBy: "USER" | "ADMIN" | null;

  user: {
    id: number;
    name: number;
    phone: string;
  };

  address: {
    raw: string;
    latitude: number;
    longitude: number;
  };

  subscription: Nullable<OrderListItemSubscription>;

  status: string;
  workers: Array<OrderListItemWorker>;

  userPrice: number;
  discount: string;
  dayCoefficient: number;
  rating: null | number;
  userOrderNumber: number;

  promocode: Nullable<{
    id: number;
    code: string;
    discount: number;
  }>;
};

export type OrderFullInterface = {
  id: number;
  source: OrderSource | null;
  status: OrderStatus;

  createdAt: string;
  datetime: string;
  houseType: AddressType;
  type: OrderType;

  canCancel: boolean;
  cancelledAt: string;
  cancelledBy: "USER" | "ADMIN" | null;
  cancelledManagerName: string | null;
  canRestore: boolean;

  user: {
    id: number;
    name: string;
    phone: string;
    text: string;
    workersText: string;
    likeWorkers: Array<{
      id: number;
      name: string;
    }>;
    dislikeWorkers: Array<{
      id: number;
      name: string;
    }>;
  };

  address: {
    id: number;
    city: number;
    text: string;
    latitude: Nullable<number>;
    longitude: Nullable<number>;
  } & AddressFields;

  services: {
    primary: Array<number>;
    secondary: Array<SecondaryServiceValue>;
    keysDeliveryAddress: Nullable<string>;
  };

  promoCode: Nullable<OrderPromocode>;
  userPrice: number;
  workTime: number;

  subscription: Nullable<{
    id: number;
    period: OrderSubscriptionPeriod;
    index: number;
  }>;

  review: Nullable<{
    id: number;
    datetime: string;
    rating: number;
    text: string;
    solution: string;
  }>;

  userComment: Nullable<string>;
  adminComment: Nullable<string>;

  price: {
    isFree: boolean;
    base: number;
    user: number;
    bonus: number;
    workers: number;
    discount: number;
    coefficient: number;
    tariff: OrderTariff;
    typeCoefficient: number;
  };
} & OrderDetailAccepted &
  OrderDetailSMS;

export type OrderDetailAccepted = {
  accepted: boolean;
  acceptedAt: string;
  acceptedBy: "USER" | "ADMIN" | null;
  acceptedManagerName: string;
};

export type OrderDetailSMS = {
  smsSent: boolean;
  smsSentAt: string;
};

export type OrderStatsByDate = {
  totalDistance: number;
  averageDistance: number;
  workersCount: number;
  ordersCount: number;
};
