import { Nullable } from "@tager/admin-services";

import {
  CreateOrderRequest,
  CustomUserData,
  PrimaryServiceCategory,
  PromoCode,
  SecondaryService,
} from "@/modules/orders/views/orders-create/typings";
import { AddressFormFields } from "@/modules/orders/typings";
import { AddressType, addressTypeOptions } from "@/enums/AddressType";

export const orderTypeOptions = [
  { value: "ROOMS", label: "Комнаты" },
  { value: "WINDOWS", label: "Окна" },
];

export const orderPeriodOptions = [
  { value: "SINGLE", label: "Разовый" },
  { value: "EVERY_MONTH", label: "Раз в месяц" },
  { value: "EVERY_2_WEEKS", label: "Раз в 2 недели" },
  { value: "EVERY_WEEK", label: "Раз в неделю" },
];

export type CityCoefficient = {
  city: number;
  coefficient: number;
};

export type FormValues = {
  user: Nullable<number | CustomUserData>;
  datetime: Nullable<string>;

  address: AddressFormFields;
  type: Nullable<string>;
  period: Nullable<string>;
  primaryServices: Array<number>;
  secondaryServices: Array<{
    id: number;
    value: number;
  }>;
  promoCode: Nullable<PromoCode>;
  keysDeliveryAddress: Nullable<string>;
  comment: Nullable<string>;
  cityCoefficients: Array<CityCoefficient>;
};

export const defaultFormValues: FormValues = {
  user: null,
  type: null,
  period: null,
  datetime: null,
  address: {
    id: null,
    type: addressTypeOptions[0],
    city: null,
    street: "",
    house: null,
    apartment: "",
    entrance: "",
    floor: null,
    wing: "",
    intercomCode: "",
  },
  primaryServices: [],
  secondaryServices: [],
  promoCode: null,
  keysDeliveryAddress: null,
  comment: null,
  cityCoefficients: [],
};

export const priceAndTimeCalculate = (
  values: FormValues,
  primaryServices: PrimaryServiceCategory[],
  secondaryServices: SecondaryService[]
): {
  price: number;
  time: number;
} => {
  const result = {
    price: 0,
    time: 0,
  };

  primaryServices.forEach((primaryServiceCategory) => {
    primaryServiceCategory.services.forEach((primaryService) => {
      if (values.primaryServices.includes(primaryService.id)) {
        result.price += primaryService.price;
        result.time += primaryService.minutes;
      }
    });
  });

  values.secondaryServices.forEach(({ id, value }) => {
    if (!value) return;

    const service = secondaryServices.find((item) => item.id === id);
    if (!service) return;

    if (service.mode === "TRUE_FALSE" || service.mode === "DELIVERY_KEYS") {
      result.price += +service.price;
      result.time += service.minutes;
    } else if (service.mode === "QUANTITY") {
      result.price += +value * service.price;
      result.time += value * service.minutes;
    } else if (service.mode === "TIME") {
      result.price += +(value / service.step) * service.price;
      result.time += (value / service.step) * service.minutes;
    }
  });

  let discountPercent = 0;

  const city: number | null = values.address?.city?.value || null;

  const dateCoefficient = city
    ? values.cityCoefficients.find((item) => item.city === city)?.coefficient ||
      null
    : 1;

  if (dateCoefficient && dateCoefficient > 1) {
    result.price *= dateCoefficient;
  } else if (dateCoefficient !== null) {
    discountPercent = 100 - dateCoefficient * 100;
  }

  if (values.promoCode) {
    discountPercent = Math.max(discountPercent, values.promoCode.discount);
  }

  result.price = result.price - (result.price / 100) * discountPercent;

  return result;
};

export function convertFormValuesToCreatePayload(
  formValues: FormValues
): CreateOrderRequest {
  const address = {
    ...formValues.address,
    type: formValues.address.type.value,
    city: formValues.address.city?.value || null,
  };

  return {
    userId:
      typeof formValues.user === "number" ? Number(formValues.user) : null,
    userNew: typeof formValues.user !== "number" ? formValues.user : null,
    address,
    datetime: String(formValues.datetime),
    period: String(formValues.period),
    primaryServices: formValues.primaryServices,
    secondaryServices: formValues.secondaryServices,
    promoCode: formValues.promoCode ? formValues.promoCode.code : null,
    comment: formValues.comment,
    keysDeliveryAddress: formValues.keysDeliveryAddress,
  };
}
