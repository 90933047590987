import { ColumnDefinition } from "@tager/admin-ui";

import { UserDeviceModel } from "@/modules/users/typings";

export const COLUMN_DEFS: Array<ColumnDefinition<UserDeviceModel>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    width: "50px",
    headStyle: {
      textAlign: "center",
    },
    style: {
      textAlign: "center",
    },
  },
  {
    id: 1,
    name: "Активен",
    field: "active",
    width: "80px",
    type: "boolean",
    view: "TICK",
  },
  {
    id: 2,
    name: "Платформа",
    field: "platform",
    width: "100px",
    format: ({ row }) => {
      if (row.platform === "WEB") {
        return "Web";
      }
      if (row.platform === "ANDROID") {
        return "Android " + row.systemVersion;
      }
      if (row.platform === "IOS") {
        return "iOS " + row.systemVersion;
      }

      return "";
    },
    headStyle: {
      textAlign: "center",
    },
    style: {
      textAlign: "center",
    },
  },
  {
    id: 3,
    name: "Устройство / Браузер",
    field: "device",
  },
  {
    id: 4,
    name: "Device ID",
    field: "deviceId",
  },
  {
    id: 5,
    name: "App Version",
    field: "appVersion",
  },
  {
    id: 6,
    name: "Создан",
    field: "createdAt",
    type: "datetime",
  },
  {
    id: 6,
    name: "Активен",
    field: "activeAt",
    type: "datetime",
  },
];
