import { OptionType } from "@tager/admin-ui";

export enum AddressType {
  Apartment = "APARTMENT",
  House = "HOUSE",
}

export const addressTypeOptions: Array<OptionType<AddressType>> = [
  { label: "Квартира", value: AddressType.Apartment },
  { label: "Дом", value: AddressType.House },
];
