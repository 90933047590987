import { OptionType } from "@tager/admin-ui";

export enum OrderTariff {
  Standard = "STANDARD",
  General = "GENERAL",
  AfterRepair = "AFTER_REPAIR",
  TimeBasis = "TIME_BASIS",
}

export function getOrderTariffLabel(value: OrderTariff | null): string {
  switch (value) {
    case OrderTariff.Standard:
      return "Стандартный";
    case OrderTariff.General:
      return "Генеральная уборка";
    case OrderTariff.AfterRepair:
      return "Уборка после ремонта";
    case OrderTariff.TimeBasis:
      return "Почасовая уборка";
    default:
      return "Неизвестный";
  }
}

export const OrderTariffOptions: Array<OptionType<OrderTariff>> = [
  {
    value: OrderTariff.Standard,
    label: getOrderTariffLabel(OrderTariff.Standard),
  },
  {
    value: OrderTariff.General,
    label: getOrderTariffLabel(OrderTariff.General),
  },
  {
    value: OrderTariff.AfterRepair,
    label: getOrderTariffLabel(OrderTariff.AfterRepair),
  },
  {
    value: OrderTariff.TimeBasis,
    label: getOrderTariffLabel(OrderTariff.TimeBasis),
  },
];
