import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormFieldOptionsSwitcher = _resolveComponent("FormFieldOptionsSwitcher")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)))
  }, [
    _createVNode(_component_FormFieldOptionsSwitcher, {
      value: _ctx.values.type,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.type) = $event)),
      options: _ctx.typeOptions
    }, null, 8, ["value", "options"]),
    _createVNode(_component_FormField, {
      value: _ctx.values.sum,
      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.sum) = $event)),
      label: "Сумма",
      type: "number"
    }, null, 8, ["value"]),
    _createVNode(_component_FormField, {
      value: _ctx.values.message,
      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.values.message) = $event)),
      label: "Сообщение",
      type: "textarea"
    }, null, 8, ["value"]),
    _createVNode(_component_BaseButton, {
      type: "submit",
      disabled: _ctx.isDisabled || _ctx.loading,
      loading: _ctx.loading
    }, {
      default: _withCtx(() => [
        _createTextVNode("Применить")
      ]),
      _: 1
    }, 8, ["disabled", "loading"])
  ], 32))
}