
import { defineComponent, onMounted } from "vue";

import {
  BaseButton,
  ColumnDefinition,
  DataTable,
  DeleteIcon,
  EditIcon,
  useDataTable,
} from "@tager/admin-ui";
import { isNotNullish, useResourceDelete } from "@tager/admin-services";

import {
  deleteUserAddress,
  getAddressesUpdateUrl,
  getUserAddresses,
} from "@/modules/users";
import { UserAddressModel } from "@/modules/users/typings";

const COLUMN_DEFS: Array<ColumnDefinition<UserAddressModel>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    style: { width: "80px", textAlign: "center" },
    headStyle: { width: "80px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 2,
    name: "Адрес",
    field: "raw",
  },
  {
    id: 3,
    name: "Детали",
    field: "",
    type: "key-value",
    format: ({ row }) =>
      [
        row.type
          ? {
              key: "Тип",
              value: row.type === "APARTMENT" ? "Квартира" : "Частный дом",
            }
          : null,
        row.details.city
          ? { key: "Город", value: String(row.details.city) }
          : null,
        row.details.street
          ? { key: "Улица", value: String(row.details.street) }
          : null,
        row.details.house
          ? { key: "Дом", value: String(row.details.house) }
          : null,
        row.details.wing
          ? { key: "Корпус", value: String(row.details.wing) }
          : null,
        row.details.apartment
          ? { key: "Квартира", value: String(row.details.apartment) }
          : null,
        row.details.entrance
          ? { key: "Подъезд", value: String(row.details.entrance) }
          : null,
        row.details.floor
          ? { key: "Этаж", value: String(row.details.floor) }
          : null,
        row.details.intercomCode
          ? { key: "Код от подъезда", value: String(row.details.intercomCode) }
          : null,
      ].filter(isNotNullish),
    options: {
      view: "table",
    },
  },
  {
    id: 3,
    name: "Заказы",
    field: "",
    type: "key-value",
    format: ({ row }) => [
      { key: "Активные", value: String(row.ordersActiveCount) },
      { key: "Завершенные", value: String(row.ordersCompletedCount) },
    ],
    options: {
      view: "table",
    },
  },
  {
    id: 4,
    name: "Статус",
    field: "status",
    type: "badge",
    width: "100px",
    format: ({ row }) => ({
      label: row.isDeleted ? "Удален" : "Активен",
      color: row.isDeleted ? "var(--danger)" : "var(--green)",
    }),
  },
  {
    id: 5,
    name: "Действия",
    field: "actions",
    style: { width: "100px", textAlign: "center", whiteSpace: "nowrap" },
    headStyle: { width: "100px", textAlign: "center" },
  },
];

export default defineComponent({
  name: "UsersEditAddresses",
  components: {
    EditIcon,
    BaseButton,
    DeleteIcon,
    DataTable,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const {
      fetchEntityList: fetchData,
      isLoading: isRowDataLoading,
      rowData: data,
      errorMessage,
    } = useDataTable<UserAddressModel>({
      fetchEntityList: () => getUserAddresses(props.id),
      initialValue: [],
      resourceName: "Users Addresses List",
    });

    onMounted(() => {
      fetchData();
    });

    const { isDeleting, handleResourceDelete } = useResourceDelete({
      deleteResource: deleteUserAddress,
      resourceName: "Delete Address",
      onSuccess: fetchData,
    });

    function isBusy(id: number): boolean {
      return isRowDataLoading.value || isDeleting(id);
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: data,
      isRowDataLoading,
      isBusy,
      errorMessage: errorMessage,
      handleResourceDelete,
      getAddressesUpdateUrl,
    };
  },
});
