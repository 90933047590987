import { OptionType } from "@tager/admin-ui";
import { Nullable } from "@tager/admin-services";

import { AddressType, addressTypeOptions } from "@/enums/AddressType";
import {
  AddressUpdatePayload,
  UserAddressFullModel,
} from "@/modules/users/typings";

export type AddressFormValues = {
  type: OptionType<AddressType>;
  city: Nullable<OptionType<number>>;
  street: Nullable<string>;
  house: Nullable<string>;
  apartment: Nullable<string>;
  wing: Nullable<string>;
  entrance: Nullable<string>;
  intercomCode: Nullable<string>;
  floor: Nullable<number>;
};

export const getInitialAddressFormValues = (
  model: UserAddressFullModel,
  cityOptions: Array<OptionType<number>>
): AddressFormValues => {
  return {
    type:
      addressTypeOptions.find((item) => item.value === model.type) ||
      addressTypeOptions[0],
    city: cityOptions.find((item) => item.value === model.city?.id) || null,
    street: model.street,
    house: model.house,
    apartment: model.apartment,
    wing: model.wing,
    entrance: model.entrance,
    intercomCode: model.intercomCode,
    floor: model.floor ? +model.floor : null,
  };
};

export function convertAddressFormValuesToUpdatePayload(
  values: AddressFormValues
): AddressUpdatePayload {
  return {
    type: values.type?.value || "HOUSE",
    city: values.city?.value || 0,
    street: values.street,
    house: values.house,
    apartment: values.apartment,
    wing: values.wing,
    entrance: values.entrance,
    intercomCode: values.intercomCode,
    floor: values.floor,
  };
}
