import { ColumnDefinition, useDataTable } from "@tager/admin-ui";

import { UserAddressModel, UserBalanceModel } from "@/modules/users/typings";
import { getUserBalance } from "@/modules/users";

export const COLUMN_DEFS: Array<ColumnDefinition<UserAddressModel>> = [
  {
    id: 1,
    name: "Дата / Время",
    field: "datetime",
    width: "180px",
    type: "datetime",
  },
  {
    id: 3,
    name: "Сумма",
    field: "sum",
    width: "100px",
    style: {
      textAlign: "center",
    },
    headStyle: {
      textAlign: "center",
    },
  },
  {
    id: 4,
    name: "Баланс",
    field: "balance",
    width: "100px",
    style: {
      textAlign: "center",
    },
    headStyle: {
      textAlign: "center",
    },
  },
  {
    id: 2,
    name: "Сообщение",
    field: "info",
  },
];

export const useBalanceData = (id: number) =>
  useDataTable<UserBalanceModel>({
    fetchEntityList: () => getUserBalance(id),
    initialValue: [],
    resourceName: "Users Balance List",
  });

export const rowCssClass = (row: UserBalanceModel): string =>
  row.sum < 0 ? "balance-row--negative" : "balance-row--positive";
