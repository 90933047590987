
import { defineComponent, onMounted, ref, watch } from "vue";
import { clone } from "lodash";

import { Spinner } from "@tager/admin-ui";
import { Nullable, useResource } from "@tager/admin-services";

import { getUserAddresses } from "@/modules/orders/views/orders-create/services";
import {
  defaultAddressValues,
  UserAddress,
} from "@/modules/orders/views/orders-create/typings";
import {
  formatAddressInfo,
  validateCustomAddress,
  formatAddressMain,
} from "@/modules/orders/views/orders-create/helpers";
import AddressForm from "@/modules/orders/components/AddressForm.vue";
import { AddressFormFields } from "@/modules/orders/typings";
import { addressTypeOptions } from "@/enums/AddressType";

export default defineComponent({
  name: "OrdersAddress",
  components: {
    AddressForm,
    Spinner,
  },
  props: {
    userId: {
      type: Number,
      default: null,
      required: false,
    },
    id: {
      type: Number,
      default: null,
      required: false,
    },
  },
  emits: ["change"],
  setup(props, { emit }) {
    const activeAddressId = ref<number | null>(props.id);
    const customAddress = ref<AddressFormFields>(defaultAddressValues);

    const [fetchAddresses, { data: addressesData, loading: addressesLoading }] =
      useResource<Nullable<Array<UserAddress>>>({
        fetchResource: () =>
          props.userId
            ? getUserAddresses(props.userId)
            : new Promise((resolve) => resolve({ data: null })),
        initialValue: null,
        resourceName: "User Addresses",
      });

    const init = () => {
      if (props.userId) {
        addressesData.value = null;
        fetchAddresses();
      } else {
        addressesData.value = [];
        activeAddressId.value = -1;
      }
    };
    watch(() => props.userId, init);
    onMounted(init);

    watch(activeAddressId, () => {
      if (activeAddressId.value === -1) {
        customAddress.value = clone(defaultAddressValues);
      }
    });

    watch([addressesData], () => {
      if (!addressesData.value) {
        emit("change", null);
        return;
      }

      if (addressesData.value?.length === 0) {
        onSelectAddress(-1);
      } else if (addressesData.value) {
        onSelectAddress(props.id ?? addressesData.value[0].id);
      }
    });

    const onCustomAddressUpdate = () => {
      if (!customAddress.value || !validateCustomAddress(customAddress.value)) {
        emit("change", null);
      } else {
        emit("change", { ...customAddress.value, id: null });
      }
    };

    const onSelectAddress = (addressId: number) => {
      if (addressId === activeAddressId.value) return;

      activeAddressId.value = addressId;
      if (addressId === -1) {
        customAddress.value = clone(defaultAddressValues);
        emit("change", null);
      } else {
        const addressModel =
          addressesData.value?.find((item) => item.id === addressId) || null;

        emit("change", {
          ...addressModel,
          city: addressModel
            ? {
                value: addressModel.city.id,
                label: addressModel.city.name,
              }
            : null,
          type:
            addressTypeOptions.find(
              (item) => item.value === addressModel?.type
            ) || addressTypeOptions[0],
        });
      }
    };

    return {
      loading: addressesLoading,
      data: addressesData,
      activeAddressId,
      onSelectAddress,
      formatAddressMain,
      formatAddressInfo,
      customAddress,
      onCustomAddressUpdate,
    };
  },
});
