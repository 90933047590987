export enum OrderSource {
  Web = "WEB",
  Admin = "ADMIN",
  Mobile = "MOBILE",
}

export function getOrderSourceLabel(orderSource: OrderSource | null): string {
  switch (orderSource) {
    case OrderSource.Web:
      return "Сайт";
    case OrderSource.Admin:
      return "Админка";
    case OrderSource.Mobile:
      return "Приложение";
    default:
      return "Неизвестный";
  }
}
