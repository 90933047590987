
import { defineComponent, onMounted } from "vue";

import { DataTable, useDataTable } from "@tager/admin-ui";

import { getUserDevices } from "@/modules/users";
import { UserDeviceModel } from "@/modules/users/typings";
import { COLUMN_DEFS } from "@/modules/users/users-edit/containers/UsersEditDevices/UsersEditDevices.helpers";

export default defineComponent({
  name: "UsersEditDevices",
  components: {
    DataTable,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const {
      fetchEntityList: fetchData,
      isLoading: isRowDataLoading,
      rowData: data,
      errorMessage,
    } = useDataTable<UserDeviceModel>({
      fetchEntityList: () => getUserDevices(props.id),
      initialValue: [],
      resourceName: "Users Devices List",
    });

    onMounted(() => {
      fetchData();
    });

    function isBusy(id: number): boolean {
      return isRowDataLoading.value;
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: data,
      isRowDataLoading,
      isBusy,
      errorMessage: errorMessage,
    };
  },
});
