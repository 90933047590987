import { formatDateTime, OptionType } from "@tager/admin-ui";
import { isNotNullish } from "@tager/admin-services";

import { OrderInterface } from "@/modules/orders/typings";
import { getOrderSourceLabel } from "@/enums/OrderSource";
import { getOrderTariffLabel } from "@/modules/orders/enums/OrderTariff";

function getOrderUrl(id: number, status?: string): string {
  return status === "ACTIVE" ? "/orders/edit/" + id : "/orders/view/" + id;
}

export const getColumnDefs = () => {
  return [
    {
      id: 1,
      name: "",
      type: "name",
      field: "id",
      format: ({ row }: { row: OrderInterface }) => ({
        adminLink: {
          url: getOrderUrl(row.id, row.status),
          text: "№ " + String(row.id),
        },
        websiteLink: null,
        options: {
          shouldUseRouter: true,
        },
        paramList: [
          {
            name: "Дата создания",
            value: formatDateTime(new Date(row.createdAt)),
          },
          {
            name: "Источник",
            value: getOrderSourceLabel(row.source),
          },
          {
            name: "Тариф",
            value: getOrderTariffLabel(row.tariff),
          },
          {
            name: "Подтвержден",
            value: row.accepted
              ? row.acceptedBy === "ADMIN"
                ? "Менеджером"
                : "Клиентом"
              : "-",
          },
          {
            name: "SMS отправлено",
            value: row.smsSent ? "Да" : "Нет",
          },
          row.dayCoefficient !== 1
            ? { name: "Коэффициент", value: String(row.dayCoefficient) }
            : null,
          row.promocode !== null
            ? {
                name: "Промокод",
                value:
                  String(row.promocode.code) +
                  " (" +
                  row.promocode.discount +
                  "%)",
              }
            : null,
          row.status !== "CANCELLED"
            ? {
                name: "Оценка",
                value: row.rating ? String(row.rating) : "-",
              }
            : null,
          row.status !== "CANCELLED"
            ? {
                name: "Номер заказа",
                value: String(row.userOrderNumber),
              }
            : null,
        ].filter(isNotNullish),
      }),
      width: "210px",
    },
    {
      id: 2,
      name: "Дата уборки",
      field: "datetime",
      type: "datetime",
      width: "164px",
    },
    {
      id: 3,
      name: "Клиент",
      field: "user",
      width: "190px",
    },
    {
      id: 4,
      name: "Подписка",
      field: "subscription",
      width: "140px",
    },
    {
      id: 5,
      name: "Адрес",
      field: "address",
    },
    {
      id: 6,
      name: "Клинеры",
      field: "workers",
    },
    {
      id: 7,
      name: "Цена, руб.",
      field: "userPrice",
      width: "110px",
    },
    {
      id: 8,
      name: "Скидка",
      field: "discount",
      style: { width: "70px", textAlign: "center" },
      headStyle: { width: "70px", textAlign: "center" },
    },
  ];
};

export const SORT_OPTIONS: Array<OptionType> = [
  {
    value: "created_date_desc",
    label: "По дате создания - по убыванию",
  },
  {
    value: "created_date_asc",
    label: "По дате создания - по возрастанию",
  },
  {
    value: "date_asc",
    label: "По дате уборки - по возрастанию",
  },
  {
    value: "date_desc",
    label: "По дате уборки - по убыванию",
  },
];
