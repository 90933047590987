export const ROUTE_PATHS = {
  WORKER_ACTIVE_LIST: "/workers",
  WORKER_ARCHIVE_LIST: "/workers/archive",
  WORKER_FORM: "/workers/:workerId",
  COMPANY_LIST: "/companies",
  COMPANY_FORM: "/companies/:companyId",
  GUEST_ORDER_LIST: "/guest-orders",
  PROMOCODE_LIST: "/promocodes",
  PROMOCODE_FORM: "/promocodes/:promocodeId",
  STATISTIC_LIST: "/workers/statistic",
  TRAINING_LIST: "/training",
  TRAINING_FORM: "/training/:trainingId",
  TIMETABLE_LIST: "/timetable",
  SUBSCRIPTION_LIST: "/subscriptions",
  SUBSCRIPTION_LIST_COMPETED: "/subscriptions/completed",
  SUBSCRIPTION_FORM: "/subscriptions/:subscriptionId",
  REVIEWS_LIST: "/reviews",

  USERS_LIST: "/users",
  USERS_EDIT: "/users/:userId",
  USERS_CREATE: "/users/create",
  ADDRESSES_EDIT: "/addresses/:addressId",

  CITIES_LIST: "/cities",
  CITIES_EDIT: "/cities/:id",
  CITIES_CREATE: "/cities/create",

  NOTES_LIST: "/notes",
  NOTES_EDIT: "/notes/:id",
  NOTES_CREATE: "/notes/create",

  CALENDAR: "/calendar",
  STATS: "/stats",

  ORDERS_ACTIVE_LIST: "/orders",
  ORDERS_COMPLETED_LIST: "/orders/completed",
  ORDERS_CANCELLED_LIST: "/orders/cancelled",

  ORDERS_VIEW: "/orders/view/:orderId",
  ORDERS_EDIT: "/orders/edit/:orderId",
  ORDERS_CREATE: "/orders/create",
  ORDERS_FOR_DAY: "/orders/for-day",
  ORDERS_MAP: "/orders/map",

  SERVICE_CATEGORY_LIST: "/services",
  SERVICE_CATEGORY_FORM: "/services/:serviceCategoryId",
  SERVICE_CATEGORY_MULTIPLE_CHANGE: "/services/multiple-change/:categoryId",

  ADDITIONAL_SERVICE_CATEGORY_LIST: "/services/:serviceCategoryId/items",
  ADDITIONAL_SERVICE_CATEGORY_FORM:
    "/services/:serviceCategoryId/items/:additionalServiceId",

  SECONDARY_SERVICE_LIST: "/services/secondary",
  SECONDARY_SERVICE_FORM: "/services/secondary/:additionalServiceId",
};
