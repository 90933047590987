import { request, ResponseBody } from "@tager/admin-services";
import { getFilterParams } from "@tager/admin-ui";

import { CountData } from "@/requests";
import {
  AddressUpdatePayload,
  UserAddressFullModel,
  UserAddressModel,
  UserBalanceModel,
  UserCreatePayload,
  UserDeviceModel,
  UserFullInterface,
  UserInterface,
  UserUpdatePayload,
  UserWorkersUpdatePayload,
} from "@/modules/users/typings";

export function getUsersCount(city?: number): Promise<ResponseBody<CountData>> {
  const query = city ? { city } : {};
  return request.get({ path: "/admin/users/count", params: query });
}

export function getUsers(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<UserInterface>>> {
  return request.get({ path: "/admin/users", params });
}

export function getUserBalance(
  id: number
): Promise<ResponseBody<Array<UserBalanceModel>>> {
  return request.get({ path: "/admin/users/" + id + "/balance" });
}

export function getUserDevices(
  id: number
): Promise<ResponseBody<Array<UserDeviceModel>>> {
  return request.get({ path: "/admin/users/" + id + "/devices" });
}

export function getUserAddresses(
  id: number
): Promise<ResponseBody<Array<UserAddressModel>>> {
  return request.get({
    path: "/admin/addresses",
    params: { ...getFilterParams({ user: id }) },
  });
}

export function updateAddress(
  id: number,
  body: AddressUpdatePayload
): Promise<ResponseBody<UserFullInterface>> {
  return request.put({
    path: `/admin/addresses/${id}`,
    body,
  });
}

export function deleteUserAddress(
  id: number | string
): Promise<{ success: boolean }> {
  return request.delete({ path: "/admin/addresses/" + id });
}

export function getUserAddress(
  id: number
): Promise<ResponseBody<UserAddressFullModel>> {
  return request.get({ path: "/admin/addresses/" + id });
}

export function getUser(id: number): Promise<ResponseBody<UserFullInterface>> {
  return request.get({ path: "/admin/users/" + id });
}

export function createUser(
  payload: UserCreatePayload
): Promise<ResponseBody<UserFullInterface>> {
  return request.post({ path: "/admin/users", body: payload });
}

export function updateUser(
  userId: number,
  payload: UserUpdatePayload
): Promise<ResponseBody<UserFullInterface>> {
  return request.put({
    path: `/admin/users/${userId}`,
    body: payload,
  });
}

export async function setUserText(userId: number, value: string) {
  return request.put({
    path: "/admin/users/" + userId + "/text",
    body: {
      text: value,
    },
  });
}

export async function setUserWorkersComment(userId: number, value: string) {
  return request.put({
    path: "/admin/users/" + userId + "/workers-text",
    body: {
      text: value,
    },
  });
}

type UpdateUserWorkersResponse = {
  likeWorkers: Array<{
    id: number;
    name: string;
  }>;
  dislikeWorkers: Array<{
    id: number;
    name: string;
  }>;
};

export function updateUserWorkers(
  userId: number,
  payload: UserWorkersUpdatePayload
): Promise<ResponseBody<UpdateUserWorkersResponse>> {
  return request.put({
    path: `/admin/users/${userId}/workers`,
    body: payload,
  });
}

type UpdateUserBalanceRequest = {
  message: string;
  sum: number;
};

type UpdateUserBalanceResponse = {
  balance: number;
};

export function updateUserBalance(
  userId: number,
  payload: UpdateUserBalanceRequest
): Promise<ResponseBody<UpdateUserBalanceResponse>> {
  return request.post({
    path: `/admin/users/${userId}/balance`,
    body: payload,
  });
}
